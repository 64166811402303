import React, { useCallback, useRef } from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"

import * as css from "./tos.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { Link } from "@gatsbyjs/reach-router"
import MetaTags from "../components/MetaTags"
import metaPreviewImage from "../assets/images/thumbnails/thumbnail_index.jpg"

// markup
const TermsOfService = () => {
    return (
        <>
            <MetaTags
                metaDescription="Kebijakan Layanan"
                metaTitle="Kebijakan Layanan"
                metaPath="/tos"
                metaPreviewImage={metaPreviewImage}
            />
            <Header />

            <Container className={css.section}>
                <Row>
                    <Col>
                        <h1>Kebijakan Layanan</h1>

                        <h2>1. Kebijakan</h2>
                        <p>
                            Dengan mengakses situs ini, anda setuju untuk terikat dengan kebijakan layanan,
                            semua regulasi dan hukum yang berlaku, dan bersedia mematuhi hukum yang berlaku.
                            <br />
                            Jika anda tidak setuju dengan kebijakan layanan, anda tidak diperbolehkan
                            untuk mengakses situs ini. Beberapa konten dan materi dari situs ini dilindungi oleh
                            hak cipta dan merek dagang.
                        </p>

                        <h2>2. Mendaftar dan Masuk</h2>
                        <p>
                            Ketika mendaftar, kami meminta anda untuk menyediakan Nama Lengkap, Alamat Surat Elektronik,
                            dan Nomor Telepon yang aktif. Kami berusaha menjaga informasi ini tetap seperti yang sudah tersebut dalam <Link to={'/privacy'}>Privasi</Link>.

                            <br />
                            <br />
                            Anda dapat mengubah atau menghapus informasi tersebut kapan saja melalui menu Dasbor. Penghapusan Akun secara permanen bisa dilakukan melalui menu Pengaturan Akun.
                            <br />
                            <br />
                            Akun anda tidak dapat berpindah kepemilikan dengan alasan apa pun. Hal ini berkaitan dengan alasan
                            keamanan agar menghindari akun anda digunakan oleh pihak yang tidak bertanggung jawab.
                        </p>

                        <h2>3. Informasi Pribadi</h2>
                        <p>
                            Mengumpulkan, menyimpan, memproses, dan menggunakan informasi personal seperti Nama dan Alamat Surel ketika menggunakan aplikasi
                            adalah bagian dari <Link to={'/privacy'}>Privasi</Link>, yang merupakan bagian penting dari Kebijakan Layanan ini.

                        </p>

                        <h2>4. Konten</h2>
                        <p>
                            Kami tidak memeriksa segala gambar atau video yang dikirimkan oleh pengguna. Segala bentuk konten yang
                            ada diluar situs ini bukan merupakan tanggung jawab kami.

                        </p>

                        <h2>5. Transaksi</h2>
                        <p>
                            Semua transaksi dilakukan secara otomatis dengan bantuan layanan MidTrans.
                            <br />
                            Semua kebijakan dan transaksi berlaku dibawah hukum Republik Indonesia.
                        </p>


                        <h2>6. Hubungi Kami</h2>
                        <p>
                            Kami menyediakan layanan bantuan dan komplain melalui Twitter{" "}
                            <a href="https://x.com/huedaya">@huedaya <FontAwesomeIcon icon={faTwitter} /></a> dan{" "}
                            <a href="https://x.com/KtpAnabul">@KtpAnabul <FontAwesomeIcon icon={faTwitter} /></a>.


                        </p>

                        <br />
                        <br />
                        <p> Pembaharuan Terakhir: 18 Mei 2023
                        </p>

                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default TermsOfService
